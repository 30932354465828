import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import * as config from '../config';

import "../css/pages/Start.css";

const Blok = () => {
	return (
		<div className="large-block">
			<p>Dzień dobry</p>
		</div>
	);
};

const Start = () => {
  return (
	<div className="Start">		
		<Helmet>
			<title>{config.SITE_NAME}</title>
		</Helmet>
		
		<Blok />
		
		<section className="start-section">
			<div className="start-section-header">
				<h1>Newsroom</h1>
				<Link to="/newsroom"><button>Przejdź</button></Link>
			</div>
		</section>
	</div>
  );
};

export default Start;
