import React from 'react';
import { Helmet } from "react-helmet";

import * as config from '../config';

const Kontakt = () => {
  return (
	<div className="Kontakt">
		<Helmet>
			<title>Kontakt - {config.SITE_NAME}</title>
		</Helmet>
		
		<h1>Kontakt</h1>
		<p>hello@molkuski.com</p>
	</div>
  );
};

export default Kontakt;
