import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import Start from './pages/Start';
import Projekty from './pages/Projekty';
import Newsroom from './pages/Newsroom';
import Kontakt from './pages/Kontakt';

import "./css/fonts.css";
import "./css/App.css";

import Background from "./components/Background";

const App = () => {
    return (
        <div className="root">
            <Background />
            
            <Router>
                <Header />
                
                <main>
                    <Routes>
                        <Route path="/" element={<Start/>} />
                        <Route path="/projekty" element={<Projekty />} />
                        <Route path="/newsroom" element={<Newsroom/>} />
                        <Route path="/kontakt" element={<Kontakt />} />
                    </Routes>
                </main>
                
                <Footer />
            </Router>
        </div>
    );
};

export default App;
