import React from 'react';
import { Helmet } from "react-helmet";

import * as config from '../config';

const Projekty = () => {
  return (
	<div className="Projekty">
		<Helmet>
			<title>Projekty - {config.SITE_NAME}</title>
		</Helmet>
		
		<h1>Projekty</h1>
		<p>tu beda moje projekty</p>
	</div>
  );
};

export default Projekty;
