import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';

import * as config from '../config';

import "../css/pages/Newsroom.css";

const Newsroom = () => {
	const [newsroom, setNewsroom] = useState([]);
	
	useEffect(() => {
		axios.get('http://molkuski.com:3001/api/newsroom')
			.then(response => {
				setNewsroom(response.data);
			})
			.catch(error => {
				console.error('There was an error fetching the data!', error);
			});
	}, []);
	
	return (
		<div className="Newsroom">
			<Helmet>
				<title>Newsroom - {config.SITE_NAME}</title>
			</Helmet>
			
			<h1>Newsroom</h1>
			
			{newsroom.map(item => (
				<div className='molkuski-newsroom-artykul' key={item.id}>
					<div>
						<img src={item.baner} />
					</div>
					
					<div className='molkuski-newsroom-artykul-info'>
						<h3>{item.tytul}</h3>
						<p>{item.data}</p>
						<p>{item.opis}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default Newsroom;