import React from 'react';
import { Link } from 'react-router-dom';

import "../css/Footer.css";

const Footer = () => {
  return (
	<footer className="molkuski-footer">
		<p>Strona</p>
		<ul>
			<li><Link className="navlink no-underline" to="/">Start</Link></li>
			<li><Link className="navlink no-underline" to="/projekty">Projekty</Link></li>
			<li><Link className="navlink no-underline" to="/newsroom">Newsroom</Link></li>
			<li><Link className="navlink no-underline" to="/kontakt">Kontakt</Link></li>
		</ul>
		
		<hr></hr>
		
		<div className="molkuski-footer-bottom">
			<p>(c) 2024 - molkuski.com</p>
			
			<Link className="navlink no-underline" to="/">Polityka prywatności</Link>
		</div>
	</footer>
  );
};

export default Footer;