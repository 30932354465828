import React from 'react';
import { Link } from 'react-router-dom';

import "../css/Header.css";
import Logo from "../logo/molkuski.webp";

const Header = () => {
  return (
	<header className="molkuski-header">
		<ul>
			<li><Link className="navlink no-underline" to="/"><img src={Logo} className="molkuski_logo" alt="molkuski.com"/></Link></li>
			<li><Link className="navlink no-underline" to="/projekty">Projekty</Link></li>
			<li><Link className="navlink no-underline" to="/newsroom">Newsroom</Link></li>
			<li><Link className="navlink no-underline" to="/kontakt">Kontakt</Link></li>
		</ul>
	</header>
  );
};

export default Header;